import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Slash, cc0
} from "../assets/svgs";

import SebastienNoundry from "./w/10_SebastienNoundry.js";
import Heroglyphs       from './w/09_Heroglyphs.js'
import HybridNouns from './w/HybridNouns.js'
import SushiNouns from './w/SushiNouns.js'
import SushiPlayground from './w/SushiPlayground.js'
import Ipotetu from './w/Ipotetu.js'
import LeConnecteur from './w/LeConnecteur.js'
import Utopyc from './w/Utopyc.js'
import Ode from './w/Ode.js'
import Graffiti from './w/00_Graffiti.js'

function Items() {
  const [activeItem, setActiveItem] = useState(null);
  const itemsRef = useRef({});

  useEffect(() => {
    const handleScroll = () => {
      let mostVisibleItem = null;
      let maxVisibleHeight = 0;

      Object.keys(itemsRef.current).forEach((id) => {
        const item = itemsRef.current[id];
        if (item) {
          const rect = item.getBoundingClientRect();
          const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
          if (visibleHeight > maxVisibleHeight) {
            mostVisibleItem = id;
            maxVisibleHeight = visibleHeight;
          }
        }
      });

      if (mostVisibleItem !== null) {
        setActiveItem(mostVisibleItem);
      }
    };

    const handleKeyPress = (e) => {
      if (e.keyCode === 38) { // up arrow
        e.preventDefault();
        const prevItem = getPrevItem();
        if (prevItem) {
          itemsRef.current[prevItem].scrollIntoView({ behavior: 'smooth', block: 'start' });
          setActiveItem(prevItem);
        }
      } else if (e.keyCode === 40) { // down arrow
        e.preventDefault();
        const nextItem = getNextItem();
        if (nextItem) {
          itemsRef.current[nextItem].scrollIntoView({ behavior: 'smooth', block: 'start' });
          setActiveItem(nextItem);
        }
      }
    };

    const getPrevItem = () => {
      const ids = Object.keys(itemsRef.current);
      const currentIndex = ids.indexOf(activeItem);
      if (currentIndex > 0) {
        return ids[currentIndex - 1];
      }
      return null;
    };

    const getNextItem = () => {
      const ids = Object.keys(itemsRef.current);
      const currentIndex = ids.indexOf(activeItem);
      if (currentIndex < ids.length - 1) {
        return ids[currentIndex + 1];
      }
      return null;
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [activeItem]);

  return (
    <>

{
        // SEBASTIEN'S NOUNDRY
      }

<div id="noundry" className="item" ref={(el) => { if (el) itemsRef.current['noundry'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Sebastien's Noundry
            </h3>

          </div>
          <div className="itemDate">
            2022-24
          </div>
        </div>

        <SebastienNoundry  isCurrent={activeItem === 'noundry'}/>

        <div className="itemFooter">
          <div className="info">
            PFP collection
          </div>

          <div className="d-c">
              <span className="t">marketplace
                <Slash/>
              </span>
              <span></span>
              <span>
              <a href="https://opensea.io/collection/sebastiens-noundry" title="OpenSea - Sebastien's Noundry" target="_blank" rel="nofollow">opensea</a>

              </span>
            </div>

          <div>
            <a href="https://github.com/0x572f00/Noundry" title="Github - Sebastien's Noundry" target="_blank" rel="noreferrer">
              0x572f00/Noundry
            </a>
            <a className="licence" href="https://creativecommons.org/share-your-work/public-domain/cc0/" title="CC0" target="_blank" rel="nofollow">
              <div dangerouslySetInnerHTML={{ __html: cc0 }} />
            </a>
          </div>
        </div>
      </div>

      {
        // HEROGLYPHS
      }

      <div id="heroglyphs" className="item" ref={(el) => { if (el) itemsRef.current['heroglyphs'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Heroglyphs
            </h3>
          </div>
          <div className="itemDate">
            2024
          </div>
          <div>
            <div className="d">
              <span className="t">client
                <Slash/>
              </span>
              <span>
                <a href="https://heroglyphs.com/" title="0xMaki" target="_blank" rel="noreferrer">
                  Heroglyphs
                </a>
              </span>
            </div>
          </div>
        </div>
        <Heroglyphs isCurrent={activeItem === 'heroglyphs'} />
        <div className="itemFooter">
          <div className="info">
            procedural ASCII landscapes
          </div>
          <div>
            <a href="https://heroglyphs.com" title="Heroglyphs" target="_blank">
              https://heroglyphs.com
            </a>
          </div>

          <div className="mobile">
            <div className="d">
              <span className="t">client
                <Slash/>
              </span>
              <span>
                <a href="https://heroglyphs.com/" title="0xMaki" target="_blank" rel="noreferrer">
                  Heroglyphs
                </a>
              </span>
            </div>
          </div>

          <div>
            <div className="d">
              <span className="t">contractor
                <Slash/>
              </span>
              <span>
                <a href="https://twitter.com/0xMaki" title="0xMaki" target="_blank" rel="noreferrer">
                  0xMaki
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>

      {
        // HYBRID NOUNS
      }

      <div id="hybrid" className="item" ref={(el) => { if (el) itemsRef.current['hybrid'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Hybrid&nbsp;Nouns
            </h3>
          </div>
          <div className="itemDate">
            2022-∞
          </div>
          <div>
            <div className="d">
              <span className="t">feat
                <Slash/>
              </span>
              <span>
                <a href="https://twitter.com/davidmeyreles" title="David Meyreles" target="_blank" rel="noreferrer">
                  David
                </a>
              </span>
            </div>
          </div>
        </div>

        <HybridNouns isCurrent={activeItem === 'hybrid'}/>

        <div className="itemFooter">
          <div className="info">
            one&nbsp;generative&nbsp;piece&nbsp;everyday,&nbsp;forever
          </div>
          <div>
            <a href="https://hybridnouns.wtf" title="Hybrid Nouns" target="_blank" rel="noreferrer">https://hybridnouns.wtf</a>
          </div>
          <div className="mobile">
            <div className="d">
              <span className="t">feat
                <Slash/>
              </span>
              <span>
                <a href="https://twitter.com/davidmeyreles" title="David Meyreles" target="_blank" rel="noreferrer">
                  David
                </a>
              </span>
            </div>
          </div>
          <div>
            <a href="https://github.com/0x572f00/Hybrid-Nouns" title="Github - Hybrid Nouns" target="_blank" rel="noreferrer">
              0x572f00/Hybrid-Nouns
            </a>
            <a className="licence" href="https://creativecommons.org/share-your-work/public-domain/cc0/" title="CC0" target="_blank" rel="nofollow">
              <div dangerouslySetInnerHTML={{ __html: cc0 }} />
            </a>
          </div>
        </div>

      </div>

      {
        // SUSHI NOUNS
      }
      <div id="nouns-dao-japan" className="item" ref={(el) => { if (el) itemsRef.current['nouns-dao-japan'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Nouns&nbsp;DAO&nbsp;Japan&nbsp;voxel&nbsp;assets
            </h3>
          </div>
          <div className="itemDate">
            2022-23
          </div>
          <div>
            <div className="d">
              <span className="t">feat
                <Slash/>
              </span>
              <span>
                <a href="https://twitter.com/amiyoko812" title="Amiyoko" target="_blank" rel="noreferrer">
                  Amiyoko
                </a>
              </span>
            </div>
          </div>
        </div>
        <SushiNouns isCurrent={activeItem === 'nouns-dao-japan'}/>
        <div className="itemFooter">
          <div className="info">
            70&nbsp;voxel pieces, for&nbsp;3D&nbsp;print
          </div>
          <div>
            via&nbsp;
            <a href="https://prop.house/nouns-japan/round-3/1358" title="Prop House public infrastructure">
              Prop House public infrastructure
            </a>
          </div>
          <div className="mobile">
            <div className="d">
              <span className="t">feat
                <Slash/>
              </span>
              <span>
                <a href="https://twitter.com/amiyoko812" title="Amiyoko" target="_blank" rel="noreferrer">
                  Amiyoko
                </a>
              </span>
            </div>
          </div>
          <div>
            <a href="https://github.com/0x572f00/Prop-House-1358" title="Github - Prophouse #1358" target="_blank" rel="noreferrer">
              0x572f00/Prop-House-1358
            </a>
            <a className="licence" href="https://creativecommons.org/share-your-work/public-domain/cc0/" title="CC0" target="_blank" rel="nofollow">
              <div dangerouslySetInnerHTML={{ __html: cc0 }} />
            </a>
          </div>
        </div>
      </div>

      {
        // SUSHI NOUNS PLAYGROUND
      }
      <div id="playground" className="item" ref={(el) => { if (el) itemsRef.current['playground'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Sushi Nouns
            </h3>

          </div>
          <div className="itemDate">
            2023
          </div>
          <div>
            <div className="d">
              <span className="t">assets
                <Slash/>
              </span>
              <span>
                <a href="https://github.com/Cryptocoders-wtf/sushi-nouns-dao-assets/" title="sushi-nouns-dao-assets" target="_blank" rel="noreferrer">
                  Nouns DAO Japan team
                </a>
              </span>
            </div>
          </div>
        </div>

        <SushiPlayground isCurrent={activeItem === 'playground'}/>

        <div className="itemFooter">
          <div className="info">
            playground website
          </div>
          <div>
            <a href="https://sushi.sebastien.wtf" title="Sushi Nouns DAO - 遊び場" target="_blank" rel="noreferrer">
              https://sushi.sebastien.wtf
            </a>
          </div>
          <div className="mobile">
            <div className="d">
              <span className="t">assets
                <Slash/>
              </span>
              <span>
                <a href="https://github.com/Cryptocoders-wtf/sushi-nouns-dao-assets/" title="sushi-nouns-dao-assets" target="_blank" rel="noreferrer">
                  Nouns DAO Japan team
                </a>
              </span>
            </div>
          </div>
          <div>
            <a href="https://github.com/0x572f00/Sushi-Nouns" title="Github - 遊び場" target="_blank">
              0x572f00/Sushi-Nouns
            </a>
            <a className="licence" href="https://creativecommons.org/share-your-work/public-domain/cc0/" title="CC0" target="_blank" rel="nofollow">
              <div dangerouslySetInnerHTML={{ __html: cc0 }} />
            </a>
          </div>
        </div>
      </div>

      {
        // IPOTETU
      }

      <div id="percent-for-art" className="item" ref={(el) => { if (el) itemsRef.current['percent-for-art'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Percent&nbsp;for&nbsp;art:&nbsp;Ipotêtu
            </h3>
          </div>
          <div className="itemDate">
            2018-20&nbsp;-&nbsp;2029
          </div>
          <div>
            <div className="d">
              <span className="t">feat
                <Slash/>
              </span>
              <span>
                <a href="https://www.banditnet.fr/DING/" title="Collective Ding" target="_blank" rel="noreferrer">
                  Jean-Paul, Lyn
                </a> & Elsa
              </span>
            </div>
          </div>
        </div>

        <Ipotetu isCurrent={activeItem === 'percent-for-art'}/>

        <div className="itemFooter">
          <div className="info">
            virtual&nbsp;reality&nbsp;experiment
          </div>
          <div>
            <a href="https://ipotetu.com" title="Percent for art: Ipotêtu" target="_blank">
              https://ipotetu.com
            </a>
          </div>
          <div className="mobile">
            <div className="d">
              <span className="t">feat
                <Slash/>
              </span>
              <span>
                <a href="https://www.banditnet.fr/DING/" title="Collective Ding" target="_blank" rel="noreferrer">
                  Jean-Paul, Lyn
                </a> & Elsa
              </span>
            </div>
          </div>
          <div>
            <div className="d">
              <span className="t">contractor
                <Slash/>
              </span>
              <span id="scroll-container">
                <a id="scroll-content" href="https://www.le64.fr/" title="Departmental Council of Pyrénées Atlantiques" target="_blank" rel="noreferrer">
                  {/* Departmental&nbsp;Council&nbsp;of&nbsp;Pyrénées&nbsp;Atlantiques */}
                  DCPA
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div id="connecteur" className="item" ref={(el) => { if (el) itemsRef.current['connecteur'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Le Connecteur
            </h3>
          </div>
          <div className="itemDate">
            2020
          </div>
          <div>
            <div className="d">
              <span className="t">client
                <Slash/>
              </span>
              <span>
                <a href="https://www.leconnecteur-biarritz.fr/" title="Le Connecteur" target="_blank" rel="noreferrer">
                  Crédit Agricole
                </a>
              </span>
            </div>
          </div>
        </div>
        <LeConnecteur isCurrent={activeItem === 'connecteur'}/>
        <div className="itemFooter">
          <div className="info">
            visual identity, creative coding
          </div>
          <div></div>
          <div className="mobile">
            <div className="d">
              <span className="t">client
                <Slash/>
              </span>
              <span>
                <a href="https://www.leconnecteur-biarritz.fr/" title="Le Connecteur" target="_blank" rel="noreferrer">
                  Crédit Agricole
                </a>
              </span>
            </div>
          </div>
          <div>
            <div className="d">
              <span className="t">contractor
                <Slash/>
              </span>
              <span>
                <a href="https://fstl.fr/" title="FSTL" target="_blank" rel="noreferrer">FSTL</a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div id="utopyc" className="item" ref={(el) => { if (el) itemsRef.current['utopyc'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Utopyc
            </h3>
          </div>
          <div className="itemDate">
            2017
          </div>
          <div>
            <div className="d">
              <span className="t">client
                <Slash/>
              </span>
              <span>Olivier</span>
            </div>
          </div>
        </div>
        <Utopyc isCurrent={activeItem === 'utopyc'}/>
        <div className="itemFooter">
          <div className="info">
            visual identity
          </div>
          <div className="mobile">
            <div className="d">
              <span className="t">client
                <Slash/>
              </span>
              <span>Olivier</span>
            </div>
          </div>
        </div>
      </div>

      <div id="entropie" className="item" ref={(el) => { if (el) itemsRef.current['entropie'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Ode
            </h3>
          </div>
          <div className="itemDate">
            2016
          </div>
        </div>
        <Ode isCurrent={activeItem === 'entropie'}/>
        <div className="itemFooter">
          <div className="info">
            creative coding
          </div>
          <div>
            10 editions silkscreen, 3 colors printed, 120*120cm
          </div>
          <div>
            <div className="d">
              <span className="t">print
                <Slash/>
              </span>
              <span>
                <a href="https://ivanblehaut.tumblr.com/" title="Ivan Bléhaut" target="_blank" rel="noreferrer">
                  Ivan
                </a> & <a href="https://damienauriault.com/" title="Damien Auriault" target="_blank" rel="noreferrer">
                  Damien
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div id="InternetAestheticsAndWebCultures" className="item" ref={(el) => { if (el) itemsRef.current['InternetAestheticsAndWebCultures'] = el; }}>
        <div className="itemHeader">
          <div className="itemTitle">
            <h3>
              Internet Aesthetics & Web Cultures
            </h3>
          </div>
          <div className="itemDate">
            2015
          </div>
        </div>

        <div className="work border webfolklore">
          <div className="c">
            <a href="https://mirror.xyz/0x572F0048A2424978969e9639C3E6a2825493b726/rsUG9uPhqww9effN_S-L1F_V4V0GYVuRcaVhVWIe2pk" target="_blank" rel="nofollow">
              <img src="./medias\internet-aesthetics-&-web-cultures\poster.webp" alt="Internet Aesthetics & Web Cultures" />
            </a>
          </div>
        </div>

        <div className="itemFooter">
          <div className="info text">
            writing
          </div>
          <div>
          {/* <Link to="/internet-aesthetics-and-web-cultures">read it</Link> */}
          <a href="https://mirror.xyz/0x572F0048A2424978969e9639C3E6a2825493b726/rsUG9uPhqww9effN_S-L1F_V4V0GYVuRcaVhVWIe2pk" target="_blank" rel="nofollow">
            read more
          </a>
          </div>
        </div>
      </div>

      <div id="Graffiti" className="item" ref={(el) => { if (el) itemsRef.current['Graffiti'] = el; }}>
        <Graffiti isCurrent={activeItem === 'Graffiti'}/>
      </div>
    </>
  );
}

export default Items;

