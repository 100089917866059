import React, { Component } from "react";
import { x, warpcast, deca, instagram, anchor, sun } from "../assets/svgs";
import { useDarkMode } from "../context/DarkModeContext";

const Header = () => {
  const { toggleDarkMode, isDarkMode } = useDarkMode();

  return (
    <header>
      <div className="r">
        <div className="c">
          <div>contact</div>
          <div>commissions</div>
          <div className="anchor down-m">
            <div dangerouslySetInnerHTML={{ __html: anchor }} />
          </div>
        </div>
        <div className="c email">
          <a href="mailto:gm@sebastien.wtf" target="_blank" rel="nofollow">
            gm@sebastien.wtf
          </a>

          <div></div>
          <div></div>
          <div></div>
          <div></div>

          <div>
            <button className="modeSwitch" onClick={toggleDarkMode}>
              <div dangerouslySetInnerHTML={{ __html: sun }} />
            </button>
          </div>
        </div>
      </div>

      <div className="r">
        <div className="c">
          <div></div>
          <div>social</div>
          <div className="anchor down-m">
            <div dangerouslySetInnerHTML={{ __html: anchor }} />
          </div>
        </div>
        <div className="c social">
          <div>
            <a href="https://x.com/0x572F00/" target="_blank" rel="nofollow">
              <div dangerouslySetInnerHTML={{ __html: x }} />
            </a>
          </div>
          <div>
            <a
              href="https://warpcast.com/sebastien/"
              target="_blank"
              rel="nofollow"
            >
              <div dangerouslySetInnerHTML={{ __html: warpcast }} />
            </a>
          </div>
          <div>
            <a
              href="https://deca.art/sebastien/"
              target="_blank"
              rel="nofollow"
            >
              <div dangerouslySetInnerHTML={{ __html: deca }} />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/0x572f00/"
              target="_blank"
              rel="nofollow"
            >
              <div dangerouslySetInnerHTML={{ __html: instagram }} />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;